@import '~@angular/material/theming';
@import 'app/variables.scss';

html, body { height: 100%; }

@include mat-core();

$md-mcgpalette0: (
    50 : #f2f7e7,
    100 : #dfeac4,
    200 : #cadd9d,
    300 : #b4cf75,
    400 : #a4c458,
    500 : #94ba3a,
    600 : #8cb334,
    700 : #81ab2c,
    800 : #77a325,
    900 : #659418,
    A100 : #e9ffca,
    A200 : #d4ff97,
    A400 : #c0ff64,
    A700 : #b5ff4b,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-mcgpalette1: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #a6a6a6,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$dynamic-theme-terciary: (
   50 : #fce8ec,
   100 : #f8c5ce,
   200 : #f39fae,
   300 : #ee798e,
   400 : #eb5c75,
   500 : #e73f5d,
   600 : #e43955,
   700 : #e0314b,
   800 : #dd2941,
   900 : #d71b30,
   A100 : #ffffff,
   A200 : #ffd8dc,
   A400 : #ffa5ad,
   A700 : #ff8b96,
   contrast: (
       50 : #000000,
       100 : #000000,
       200 : #000000,
       300 : #000000,
       400 : #000000,
       500 : #ffffff,
       600 : #ffffff,
       700 : #ffffff,
       800 : #ffffff,
       900 : #ffffff,
       A100 : #000000,
       A200 : #000000,
       A400 : #000000,
       A700 : #000000,
   )
);

$app-theme: mat-light-theme(
   mat-palette($md-mcgpalette0),
   mat-palette($md-mcgpalette1), 
   mat-palette($dynamic-theme-terciary)
);

@include angular-material-theme($app-theme);

* { margin: 0; font-family: 'Poppins', sans-serif; }

.spacer{
    flex: 1 1 auto;
}
.table-search {
    margin-top: 14px;
    margin-left: 8px;
}

.field.mobile {
    max-width: 140px;
}

.field {
    font-size: 11px;
}
.second-tb{
    margin-top: 65px;
}
app-image-campanha{
    margin-left: 0.5% !important;
}

.btn-swal{
   color: black;
   margin: .3125em;
   padding: .625em 1.1em;
   transition:  box-shadow .1s;
   box-shadow: 0 0 0 3px transparent;
   font-weight: 500;
   order: null;
   border: 0;
   border-radius: .25em;
   background: initial;
   background-color: $primaryColor;
   font-size: 1em;

   &:not([disabled]) {
       cursor: pointer;
   }

   &:focus {
       outline: none;
   }
}

.popup-swal{
   background-color: white !important;
   color: black !important;
}

.text-swal{
   color: black !important;
}

.card-nodata {
    background-color: hsl(0, 0%, 95%)
}

.card-info {
    border-radius: 8px;    
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
}

.card-title {
    font-size: 11pt;
    font-weight: 600;
    color: black;
    background-color: $primaryColor;
    padding: 6px 16px;
    
    span {
        font-weight: 600;
    }
}

.card-title.interno {
    padding: 4px 12px;
    font-size: small;
}

.card-content {
    padding: 12px 12px;

    span {
        font-weight: 300;
        padding: 6px 0;
    }
}

.card-content.interno {
    padding: 4px 4px;
    box-shadow: inset 0 0px 2px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

field-box {
    width: 100%;
}

.mat-list-text {
    text-transform: capitalize;
}

.btn-outline-primary {
    --bs-btn-color: #94ba3a;
    --bs-btn-border-color: #94ba3a;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #94ba3a;
    --bs-btn-hover-border-color: #94ba3a;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #94ba3a;
    --bs-btn-active-border-color: #94ba3a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #94ba3a;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #94ba3a;
    --bs-gradient: none;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #94ba3a;
    --bs-btn-border-color: #94ba3a;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #94ba3a;
    --bs-btn-hover-border-color: #94ba3a;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #94ba3a;
    --bs-btn-active-border-color: #94ba3a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #94ba3a;
    --bs-btn-disabled-border-color: #94ba3a;
}

// Parte Responsiva

button#responsive{
    color: #659418;
}

@media (max-width: 768px){
    div.ng-star-inserted{
    place-content: space-between!important;
    align-items:start !important;
    }
    span.text{
    padding-left: 40px!important;
    font-size: 10pt!important;
    font-weight: 200!important;
    align-items: start!important;
    }
    span.subtitle{
    font-size: 11pt!important;
    }
    span.big-number.ng-star-inserted{
        font-size: 35pt!important;
    }
    div#carteira.ng-star-inserted{
        display:block!important;
        }
        .mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
            font-size: 10px!important;
            font-weight: 400;
            display: block!important;
        }

    div#meusdados{
        display: block!important;
    }
    #dadosB{
        width:65%!important;
        height:65%!important;
    }
    div#tipV{
        font-size: 11pt !important;
    }
    span{
        font-size: 12px!important;
    }
}


